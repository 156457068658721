<template>
                  <p class="error" v-if="error">Selecciona una imagen de portada</p>
      <div class="carousel" v-if="images.length > 0">
        <img
          v-for="img in images"
          :key="img.id"
          :src="base_url + img.front_page"
          :class="{ selected: imageSelected == img.id }"
          @click="$emit('update:imageEvent', img.id)"
        />
      </div>
</template>
<script>
import { getCarouselImages } from '@/services/events';

export default {
  name: 'Carousel',
  props: {
    imageSelected: Number,
    sendForm: null,
    currentImage: null,
    isEdit: Boolean },
  emits: ['update:imageEvent'],
  watch: {
    sendForm: function() {
      this.error = !this.imageSelected;
      this.$emit('getFormValues', { is_ok: !!this.imageSelected, typeForm: 'front_page' });
    },
  },
  data() {
    return {
      images: [],
      error: false,
      base_url: process.env.VUE_APP_BASE_URL,
    };
  },
  mounted() {
    this.getImages();
  },
  methods: {
    async getImages() {
      await getCarouselImages()
        .then(response => {
          if (response.success) {
            if (this.isEdit) {
              const images = response.carousel || [];
              if (!images.find(item => item.id === this.currentImage.id)) {
                images.unshift(this.currentImage);
              }
              this.images = images;
            } else {
              this.images = response.carousel || [];
            }
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="sass" scoped>
.title-carousel
  font-family: "Avenir Heavy"
.error
  color: red
  font-size: 13px
  font-family: Nutmeg
  display: inline-block
.carousel
  display: flex
  flex-direction: row
  overflow: scroll
  width: 100%
  gap: 24px

  img
    width: 232px
    height: 136px
    border-radius: 15px
    object-fit: cover
    &.selected
      border: 3px solid #3FA7E1
    &:hover
      cursor: pointer
</style>
